:root {
  /* Colors */
  --primary-color: #000078;
  --secondary-color: #78ffff;
  --neutral-color: #ffffff;
  --border-color: #d4d4d4;
  --error-color: #ff0000;
  --success-color: #28a745;
  --warning-color: #ffc107;
  --info-color: #17a2b8;

  /* Typography */
  --font-family-base: "Helvetica, Arial, sans-serif";
  --font-size-base: 1.125rem; /* 18px */
  --font-weight-normal: 400;
  --font-weight-bold: 700;
  --line-height-base: 1.5;

  /* Spacing */
  --spacing-xs: 0.25rem; /* 4px */
  --spacing-sm: 0.5rem; /* 8px */
  --spacing-md: 1rem; /* 16px */
  --spacing-lg: 1.5rem; /* 24px */
  --spacing-xl: 2rem; /* 32px */

  /* Borders */
  --border-radius-small: 0.2rem; /* 3px */
  --border-radius-base: 0.25rem; /* 4px */
  --border-radius-large: 0.5rem; /* 8px */
  --border-width: 1px;

  /* Shadows */
  --box-shadow-base: 0 2px 4px rgba(0, 0, 0, 0.1);

  /* Transitions */
  --transition-speed: 0.3s;

  /* Breakpoints */
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
}

.custom-hero-text {
  color: #000078;
}

.home-container {
  width: 100%;
  height: auto;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.home-navbar-container {
  top: 0;
  width: 100%;
  display: flex;
  z-index: 100;
  position: sticky;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}
.home-navbar {
  width: 100%;
  display: flex;
  position: relative;
  max-width: var(--dl-size-size-maxcontent);
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
  justify-content: space-between;
}
.home-logo {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  justify-content: space-between;
}
.home-image {
  width: 80px;
}
.home-links-container {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-link {
  margin-right: var(--dl-space-space-tripleunit);
  text-decoration: none;
}
.home-link1 {
  margin-right: var(--dl-space-space-tripleunit);
  text-decoration: none;
}
.home-link2 {
  margin-right: var(--dl-space-space-tripleunit);
  text-decoration: none;
}
.home-link3 {
  text-decoration: none;
}
.home-cta-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-cta-btn {
  color: var(--dl-color-gray-white);
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-fiveunits);
  border-radius: 34px;
  padding-right: var(--dl-space-space-fiveunits);
  padding-bottom: var(--dl-space-space-unit);
  background-color: var(--dl-color-primary-100);
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.home-burger-menu {
  display: none;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
}
.home-icon {
  width: 36px;
  height: 36px;
}
.home-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: var(--dl-space-space-doubleunit);
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.home-top {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: space-between;
}
.home-image1 {
  width: 50px;
  object-fit: cover;
}
.home-container1 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-icon02 {
  width: 24px;
  height: 24px;
}
.home-mid {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-links-container1 {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: column;
}
.home-link4 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-link5 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-link6 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-link7 {
  text-decoration: none;
}
.home-cta-btn1 {
  color: var(--dl-color-gray-white);
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-fiveunits);
  border-radius: 34px;
  padding-right: var(--dl-space-space-fiveunits);
  padding-bottom: var(--dl-space-space-unit);
  background-color: var(--dl-color-primary-100);
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.home-bot {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  margin-top: auto;
  align-items: flex-start;
  flex-direction: column;
}
.home-social-links-container {
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: row;
}
.home-icon04 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}
.home-icon06 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}
.home-icon08 {
  width: 24px;
  height: 24px;
}
.home-hero {
  width: 50%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  margin-top: var(--dl-space-space-fiveunits);
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-tripleunit);
  margin-bottom: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: space-between;
}
.home-hero-text-container {
  width: 100%;
  display: flex;
  align-items: center;
  margin-left: 50px;
  flex-direction: column;
}
.home-heading {
  margin-bottom: var(--dl-space-space-tripleunit);
}
.home-heading1.Card-Heading {
  color: #000078; /* Change this to the desired color */
}
.home-heading2.Card-Heading {
  color: #000078; /* Change this to the desired color */
}
.home-heading3.Card-Heading {
  color: #000078; /* Change this to the desired color */
}
.home-text03 {
  color: var(--dl-color-gray-500);
  margin-bottom: var(--dl-space-space-tripleunit);
}
.home-cta-btn2 {
  color: var(--dl-color-gray-white);
  align-self: stretch;
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-width: 0px;
  border-radius: 34px;
  padding-bottom: var(--dl-space-space-doubleunit);
  background-color: var(--dl-color-primary-100);
}
.home-image2 {
  width: 50%;
  object-fit: contain;
}
.home-section-separator {
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-700);
  color: #7878a8;
}
.home-features {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  align-items: center;
  padding-top: var(--dl-space-space-tenunits);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-tenunits);
}
.home-heading-container {
  background-image: url("/brain.webp");
  background-size: cover; /* Cover the div with the image */
  background-repeat: no-repeat; /* Ensure the image doesn't repeat */
  background-position: center; /* Center the image */
  box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.2);
  height: 100vh;
  width: 100%;
  justify-content: center;
  text-align: center;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.home-text04 {
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
  color: rgb(255, 255, 255);
  font-size: 100px;
  font-weight: 700px;
  font-family: "Poppins", Sans-serif;
}
.home-text05 {
  text-align: center;
  color: rgb(255, 255, 255);
  font-size: 30px;
  font-family: "Poppins", Sans-serif;
  font-weight: 700px;
}
.home-cards-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.home-feature-card {
  width: 30%;
  display: flex;
  position: relative;
  align-self: flex-start;
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.home-image3 {
  width: 100px;
  object-fit: cover;
  margin-bottom: 20px;
}
.home-heading1 {
  margin-bottom: var(--dl-space-space-tripleunit);
}
.home-text06 {
  color: #7878a8;
  text-align: justify;
}
.home-feature-card1 {
  width: 30%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.home-image4 {
  width: 100px;
  object-fit: cover;
  margin-bottom: 20px;
}
.home-heading2 {
  margin-bottom: var(--dl-space-space-tripleunit);
}
.home-text07 {
  color: #7878a8;
  text-align: justify;
}
.home-feature-card2 {
  width: 30%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.home-image5 {
  width: 100px;
  object-fit: cover;
  margin-bottom: 20px;
}
.home-heading3 {
  margin-bottom: var(--dl-space-space-tripleunit);
}
.home-text08 {
  color: #7878a8;
  text-align: justify;
}
.home-text-products {
  color: #000078;
  text-align: center;
}
.home-products {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-top: var(--dl-space-space-tenunits);
  flex-direction: row;
  justify-content: space-evenly;
}
.home-product-card {
  width: 49%;
  display: flex;
  min-height: 500px;
  align-items: center;
  margin-bottom: var(--dl-space-space-fourunits);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-900);
}
.home-card-content {
  margin: var(--dl-space-space-fiveunits);
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  max-width: 500px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-text09 {
  text-align: justify;
  margin-bottom: var(--dl-space-space-fourunits);
  color: #000078;
}
.home-text10 {
  color: var(--dl-color-primary-700);
  text-align: justify;
  margin-bottom: var(--dl-space-space-fourunits);
  color: #7878a8;
}
.home-button {
  color: var(--dl-color-gray-white);
  width: 80%;
  align-self: center;
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-width: 0px;
  border-radius: 34px;
  padding-bottom: var(--dl-space-space-doubleunit);
  background-color: var(--dl-color-primary-100);
}
.home-product-card1 {
  width: 49%;
  display: flex;
  min-height: 500px;
  align-items: center;
  margin-bottom: var(--dl-space-space-fourunits);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-900);
}
.home-card-content1 {
  margin: var(--dl-space-space-fiveunits);
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  max-width: 500px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-text11 {
  text-align: justify;
  margin-bottom: var(--dl-space-space-fourunits);
  color: #000078;
}
.home-text12 {
  color: var(--dl-color-primary-700);
  text-align: justify;
  margin-bottom: var(--dl-space-space-fourunits);
  color: #7878a8;
}
.home-button1 {
  color: var(--dl-color-gray-white);
  width: 80%;
  align-self: center;
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-width: 0px;
  border-radius: 34px;
  padding-bottom: var(--dl-space-space-doubleunit);
  background-color: var(--dl-color-primary-100);
}
.home-product-card2 {
  width: 49%;
  display: flex;
  min-height: 500px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-900);
}
.home-card-content2 {
  margin: var(--dl-space-space-fiveunits);
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  max-width: 500px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-text13 {
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-text14 {
  color: var(--dl-color-primary-700);
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-button2 {
  color: var(--dl-color-gray-white);
  width: 80%;
  align-self: center;
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-width: 0px;
  border-radius: 34px;
  padding-bottom: var(--dl-space-space-doubleunit);
  background-color: var(--dl-color-primary-100);
}
.home-product-card3 {
  width: 49%;
  display: flex;
  min-height: 500px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-900);
}
.home-card-content3 {
  margin: var(--dl-space-space-fiveunits);
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  max-width: 500px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-text15 {
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-text16 {
  color: var(--dl-color-primary-700);
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-button3 {
  color: var(--dl-color-gray-white);
  width: 80%;
  align-self: center;
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-width: 0px;
  border-radius: 34px;
  padding-bottom: var(--dl-space-space-doubleunit);
  background-color: var(--dl-color-primary-100);
}
.home-about-us {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  align-items: flex-start;
  padding-top: var(--dl-space-space-tenunits);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: center;
}
.home-heading-container1 {
  width: 35%;
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.home-text17 {
  margin-bottom: var(--dl-space-space-tripleunit);
}
.home-secondary-text {
  color: var(--dl-color-gray-500);
  margin-bottom: var(--dl-space-space-tripleunit);
}
.home-cta-btn3 {
  color: var(--dl-color-gray-white);
  width: 80%;
  align-self: flex-start;
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-width: 0px;
  border-radius: 34px;
  padding-bottom: var(--dl-space-space-doubleunit);
  background-color: var(--dl-color-primary-100);
}
.home-text-container {
  width: 45%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-text18 {
  color: var(--dl-color-gray-500);
}
.home-text19 {
  color: var(--dl-color-gray-500);
}
.home-cta-btn4 {
  color: var(--dl-color-gray-white);
  width: 80%;
  display: none;
  align-self: flex-start;
  text-align: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-width: 0px;
  border-radius: 34px;
  padding-bottom: var(--dl-space-space-doubleunit);
  background-color: var(--dl-color-primary-100);
}
.home-section-separator1 {
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-700);
}
.home-testimonials {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  align-items: center;
  padding-top: var(--dl-space-space-tenunits);
  padding-left: var(--dl-space-space-tripleunit);
  margin-bottom: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
}
.home-text20 {
  margin-bottom: var(--dl-space-space-fiveunits);
}
.home-cards-container1 {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: var(--dl-space-space-doubleunit);
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.home-testimonial-card {
  width: 30%;
  display: flex;
  padding: var(--dl-space-space-fourunits);
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-color: var(--dl-color-gray-500);
  border-width: 1px;
  padding-left: var(--dl-space-space-doubleunit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.home-card-heading {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-doubleunit);
  border-color: var(--dl-color-gray-500);
  border-width: 1px;
  margin-bottom: var(--dl-space-space-fourunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
  justify-content: space-between;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.home-name-and-position {
  width: 50%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-position {
  color: var(--dl-color-gray-700);
}
.home-stars-container {
  width: 50%;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: row;
}
.home-icon10 {
  fill: var(--dl-color-primary-500);
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-unit);
}
.home-icon12 {
  fill: var(--dl-color-primary-500);
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-unit);
}
.home-icon14 {
  fill: var(--dl-color-primary-500);
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-unit);
}
.home-icon16 {
  fill: var(--dl-color-primary-500);
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-unit);
}
.home-icon18 {
  fill: var(--dl-color-primary-500);
  width: 24px;
  height: 24px;
}
.home-text21 {
  color: var(--dl-color-gray-500);
  text-align: left;
}
.home-testimonial-card1 {
  width: 30%;
  display: flex;
  padding: var(--dl-space-space-fourunits);
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-color: var(--dl-color-gray-500);
  border-width: 1px;
  padding-left: var(--dl-space-space-doubleunit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.home-card-heading1 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-doubleunit);
  border-color: var(--dl-color-gray-500);
  border-width: 1px;
  margin-bottom: var(--dl-space-space-fourunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
  justify-content: space-between;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.home-name-and-position1 {
  width: 50%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-position1 {
  color: var(--dl-color-gray-700);
}
.home-stars-container1 {
  width: 50%;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: row;
}
.home-icon20 {
  fill: var(--dl-color-primary-500);
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-unit);
}
.home-icon22 {
  fill: var(--dl-color-primary-500);
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-unit);
}
.home-icon24 {
  fill: var(--dl-color-primary-500);
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-unit);
}
.home-icon26 {
  fill: var(--dl-color-primary-500);
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-unit);
}
.home-icon28 {
  fill: var(--dl-color-primary-500);
  width: 24px;
  height: 24px;
}
.home-text22 {
  color: var(--dl-color-gray-500);
  text-align: left;
}
.home-testimonial-card2 {
  width: 30%;
  display: flex;
  padding: var(--dl-space-space-fourunits);
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  border-color: var(--dl-color-gray-500);
  border-width: 1px;
  padding-left: var(--dl-space-space-doubleunit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.home-card-heading2 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-doubleunit);
  border-color: var(--dl-color-gray-500);
  border-width: 1px;
  margin-bottom: var(--dl-space-space-fourunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
  justify-content: space-between;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.home-name-and-position2 {
  width: 50%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-position2 {
  color: var(--dl-color-gray-700);
}
.home-stars-container2 {
  width: 50%;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: row;
}
.home-icon30 {
  fill: var(--dl-color-primary-500);
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-unit);
}
.home-icon32 {
  fill: var(--dl-color-primary-500);
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-unit);
}
.home-icon34 {
  fill: var(--dl-color-primary-500);
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-unit);
}
.home-icon36 {
  fill: var(--dl-color-primary-500);
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-unit);
}
.home-icon38 {
  fill: var(--dl-color-primary-500);
  width: 24px;
  height: 24px;
}
.home-text23 {
  color: var(--dl-color-gray-500);
  text-align: left;
}
.home-contact {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  align-items: flex-start;
  padding-top: var(--dl-space-space-tenunits);
  padding-left: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: space-between;
}
.home-content-container {
  width: 50%;
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  align-self: stretch;
  align-items: flex-start;
  border-color: var(--dl-color-gray-700);
  border-width: 1px;
  flex-direction: column;
  justify-content: flex-start;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.home-text24 {
  margin-top: var(--dl-space-space-fiveunits);
  margin-bottom: var(--dl-space-space-fiveunits);
}
.home-locations-container {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-fiveunits);
  flex-direction: row;
}
.home-location-1 {
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-fourunits);
  flex-direction: column;
}
.home-heading4 {
  font-size: 24px;
  font-style: normal;
  font-family: Roboto;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-doubleunit);
}
.home-adress {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
}
.home-icon40 {
  width: 40px;
  height: 40px;
  margin-right: var(--dl-space-space-doubleunit);
  fill: white;
}
.home-email {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
}
.home-icon42 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
  fill: white;
}
.home-phone {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
}
.home-icon44 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}
.home-location-2 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-heading5 {
  font-size: 24px;
  font-style: normal;
  font-family: Roboto;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-doubleunit);
}
.home-adress1 {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
}
.home-icon46 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}
.home-email1 {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
}
.home-icon48 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}
.home-phone1 {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
}
.home-icon50 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}
.home-social-links-container1 {
  display: flex;
  align-self: flex-start;
  margin-top: var(--dl-space-space-fiveunits);
  align-items: flex-start;
  flex-direction: row;
}
.home-icon52 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}
.home-icon54 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
}
.home-icon56 {
  width: 24px;
  height: 24px;
}
.home-image6 {
  width: 50%;
  object-fit: cover;
}
@media (max-width: 991px) {
  .home-hero {
    flex-direction: column-reverse;
  }
  .home-hero-text-container {
    width: 80%;
    margin-left: 0px;
  }
  .home-cta-btn2 {
    width: 40%;
  }
  .home-image2 {
    width: 80%;
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .home-feature-card {
    align-self: center;
  }
  .home-about-us {
    flex-direction: column;
  }
  .home-heading-container1 {
    width: 100%;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .home-cta-btn3 {
    width: 40%;
    display: none;
  }
  .home-text-container {
    width: 100%;
  }
  .home-cta-btn4 {
    width: 280px;
    display: block;
    margin-top: var(--dl-space-space-doubleunit);
  }
  .home-locations-container {
    flex-wrap: wrap;
  }
  .home-location-1 {
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .home-image6 {
    align-self: center;
  }
}
@media (max-width: 767px) {
  .home-links-container {
    display: none;
  }
  .home-burger-menu {
    display: flex;
    padding: var(--dl-space-space-halfunit);
    margin-left: var(--dl-space-space-tripleunit);
    background-color: var(--dl-color-gray-black);
  }
  .home-icon {
    fill: var(--dl-color-gray-white);
  }
  .home-heading {
    text-align: center;
  }
  .home-text03 {
    text-align: center;
    color: #000078;
  }
  .home-cta-btn2 {
    width: auto;
    align-self: center;
  }
  .home-heading-container {
    width: 100%;
  }
  .home-cards-container {
    align-items: center;
    flex-direction: column;
  }
  .home-feature-card {
    width: 70%;
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .home-feature-card1 {
    width: 70%;
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .home-feature-card2 {
    width: 70%;
  }
  .home-card-content {
    margin: 0px;
  }
  .home-card-content1 {
    margin: 0px;
  }
  .home-card-content2 {
    margin: 0px;
  }
  .home-card-content3 {
    margin: 0px;
  }
  .home-about-us {
    align-items: center;
  }
  .home-heading-container1 {
    align-items: center;
  }
  .home-text17 {
    text-align: center;
  }
  .home-secondary-text {
    text-align: center;
  }
  .home-text20 {
    text-align: center;
  }
  .home-cards-container1 {
    align-items: center;
    flex-direction: column;
  }
  .home-testimonial-card {
    width: 70%;
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .home-stars-container {
    justify-content: flex-end;
  }
  .home-testimonial-card1 {
    width: 70%;
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .home-stars-container1 {
    justify-content: flex-end;
  }
  .home-testimonial-card2 {
    width: 70%;
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .home-stars-container2 {
    justify-content: flex-end;
  }
  .home-contact {
    padding-right: var(--dl-space-space-tripleunit);
    flex-direction: column;
  }
  .home-content-container {
    width: 100%;
  }
  .home-image6 {
    width: 100%;
  }
}
@media (max-width: 479px) {
  .home-cta-btn {
    font-size: 16px;
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
  }
  .home-burger-menu {
    margin-left: var(--dl-space-space-doubleunit);
  }
  .home-cta-btn1 {
    font-size: 16px;
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
  }
  .home-hero-text-container {
    width: 100%;
  }
  .home-image2 {
    width: 100%;
  }
  .home-feature-card {
    width: 100%;
  }
  .home-feature-card1 {
    width: 100%;
  }
  .home-feature-card2 {
    width: 100%;
  }
  .home-product-card {
    width: 100%;
  }
  .home-product-card1 {
    width: 100%;
  }
  .home-product-card2 {
    width: 100%;
    margin-bottom: var(--dl-space-space-fourunits);
  }
  .home-product-card3 {
    width: 100%;
  }
  .home-secondary-text {
    text-align: center;
  }
  .home-text20 {
    text-align: center;
  }
  .home-testimonial-card {
    width: 100%;
  }
  .home-testimonial-card1 {
    width: 100%;
  }
  .home-testimonial-card2 {
    width: 100%;
  }
  .home-content-container {
    padding-left: 0px;
    padding-right: 0px;
  }
  .home-text24 {
    text-align: center;
  }
  .home-locations-container {
    margin-bottom: 0px;
  }
  .home-location-1 {
    margin-right: 0px;
  }
  .home-social-links-container1 {
    align-self: center;
  }
}

/* Header */
.home-navbar-container {
  /* background-color: #000078; */
  /* background-color: #40434d; */
  background-color: var(--dl-color-gray-500);
}
.home-link,
.home-link1,
.home-link3 {
  color: #000078;
}
.home-link:hover,
.home-link1:hover,
.home-link3:hover {
  color: #78ffff;
}

/* Primary Button */
.home-button {
  background-color: #000078;
  color: white;
}
.home-button:hover {
  background-color: #000578; /* Slightly darker shade */
}

/* Secondary Button */
.secondary-button {
  background-color: #78ffff;
  color: #000078;
}
.secondary-button:hover {
  background-color: #58ffff; /* Slightly darker shade */
}

/* Footer */
.home-contact {
  background-color: #000078;
}
.home-contact * {
  color: white;
}
